const userGender = [
  {
    value: "man",
    text: "Masculino",
  },
  {
    value: "woman",
    text: "Feminino",
  },
  {
    value: "other",
    text: "Outro",
  },
];

export default userGender;
