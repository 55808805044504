const userScolarship = [
  {
    value: "firstIncomplete",
    text: "Fundamental Incompleto",
  },
  {
    value: "firstComplete",
    text: "Fundamental Completo",
  },
  {
    value: "secondIncomplete",
    text: "Médio Incompleto",
  },
  {
    value: "secondComplete",
    text: "Médio Completo",
  },
  {
    value: "thirdIncomplete",
    text: "Superior Incompleto",
  },
  {
    value: "thirdComplete",
    text: "Superior Completo",
  },
  {
    value: "other",
    text: "Pós",
  },
];

export default userScolarship;
